import React, { useState } from "react"

// Import SVG ※@のところを実際に使用するアイコンに差し替え
import icon_search_SVG1 from '@images/icon_search1.svg';

export function WordSearch (indexUrl, typeUrl, slug) {
  // 検索結果ページのURLをタイプ別に（Faq/Blog）
  let setUri = indexUrl + typeUrl + 'search-results/' ;

  // 入力情報の取得
  const [text, setText] = useState("");
  const [addText, setAddText] = useState("");
  const onClickAddText = () => {
    setAddText(text);
    setText("");
    // ページトップへ移動 消すかも
    window.scrollTo({
      top: 0,
    });
  }

  // inputでEnterが押された際のページ遷移 ※window.location以外での方法検討
  const handleSubmit = (event) => {
    window.location.href = setUri + "#" + addText;
    event.preventDefault();
  };

  // Faqページの検索窓  
  const searchContentFaq = <>
    <div className="md:max-w-xl relative w-full py-[5px] mx-auto rounded-md md:flex">
      <form className="relative w-full" noValidate autoComplete="off" onSubmit={handleSubmit}>
        <button className="absolute inset-y-0 left-0 flex items-center pl-3" onClick={onClickAddText}>
          <a className="flex" href={setUri + "#" + addText}>
            <span className="pr-5 w-[90%]"><img src={icon_search_SVG1} alt=""/></span>
          </a>
        </button>
        <input type="text" className="w-full py-3 pl-10 pr-4 border-[#888888] text-sm text-gray-700 bg-white rounded dark:bg-gray-900 dark:text-gray-300 
         focus:border-blue-400 dark:focus:border-blue-300 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40" 
        placeholder="検索したいワードを入力" value={text} onChange={(event) => setText(event.target.value)}/>
      </form>
    </div>
  </>;
  // Blogページの検索窓
  const searchContentBlog = <>
    <div className="md:max-w-xl mb-[20px] relative w-full py-[5px] mx-auto rounded-md md:flex ">
      <form className="md:w-[90%] relative" noValidate autoComplete="off" onSubmit={handleSubmit}>
        <button className="absolute inset-y-0 left-0 flex items-center pl-3" onClick={onClickAddText}>
          <a className="flex" href={setUri + "#" + addText}>
            <span className="pr-5"><img src={icon_search_SVG1} alt=""/></span>
          </a>
        </button>
        <input type="text" className="w-full py-3 pl-[58px] pr-4 border-[#888888] text-sm text-gray-700 bg-white rounded dark:bg-gray-900 dark:text-gray-300
         focus:border-blue-400 dark:focus:border-blue-300 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40" 
        placeholder="検索したいワードを入力" value={text} onChange={(event) => setText(event.target.value)}/>
      </form>
    </div>
  </>;

  return { Faq:searchContentFaq, Blog:searchContentBlog }
}

