import { useStaticQuery, graphql } from "gatsby";

// function名は頭大文字
// ソートが更新日の場合はfields: modifiedに変える
// ↓setType
// １．全記事データ配列：'setAll'
// ２．categorieマッチ 配列作成：'setCateg'
// ３．tagマッチ 配列作成：'setTag'
// ４．大カテゴリ別抽出：'setMainCateg'
// All＆大カテゴリ：'allandMain'
// 検索用(all contentあり)：search
// 全部：'allmix'
export function FaqGraphqlArray (checkItem, setType) {
  const data = useStaticQuery(graphql`
  query FaqPostDataQuery {
    allWpFaq(sort: {fields: date, order: DESC}) {
      edges {
        node {
          date(formatString: "YYYY/MM/DD")
          modified(formatString: "YYYY/MM/DD")
          id
          uri
          slug
          title
          excerpt
          content
          faqCategory {
            nodes {
              name
              slug
            }
          }
          faqTag {
            nodes {
              name
              slug
            }
          }
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    placeholder: BLURRED
                    layout: CONSTRAINED
                    transformOptions: { cropFocus: CENTER }
                  )
                }
              }
            }
          }
        }
      }
    }
    allWpFaqCategory(
      sort: {fields: databaseId, order: ASC}
      filter: {parentId: {eq: null}}
    ) {
      totalCount
      edges {
        node {
          name
					slug
          parentId
          databaseId
        }
      }
    }
  }
  `
  )
  // 配列生成
  let result, removals, posts, postDate, postModified, postId, postUri, postSlug, postTitle, postExcerpt, postContent, 
  postCategorie, postCategories, postCategSlug, postCategsSlug, postTag, postTags, postTagSlug, postTagsSlug, 
  postGatsbyImageData, postImageAltText, postDescription, arrayPostsData, checkCategorie, checkTag ;
  const allEdges = data.allWpFaq.edges;
  const allCategoryEdges = data.allWpFaqCategory.edges;

  const alldataArray = []; // 全データ用
  let checkCategArray = []; // カテゴリチェック用
  let checkTagArray = []; // タグチェック用
  let subArray = []; // 処理用配列
  let mainCategArray = []; // 大カテゴリ用
  let mainCategUrlArray = []; // 大カテゴリ用
  let searchCategArray = [];
  let makeCategs=[];
  let countLists = [];

  // 大カテゴリ（不要？）
  if ( setType === "setMainCateg" || setType === 'allandMain' || setType === 'allmix') {
    allCategoryEdges.map((result) => { // 大カテゴリ名を配列に格納
      subArray.push(result.node.name)
      return subArray
      });  
    removals = ['未分類','faq-main-categorie-test']; // 除外大カテゴリ設定
    mainCategArray = subArray.filter(function(v){ // 除外大カテゴリを配列から削除
      return ! removals.includes(v);
    });
    // カテゴリ検索生成用  
    for(let i = 0; i < mainCategArray.length ; i++){ 
      makeCategs.push([mainCategArray[i]])
    }
  }

  // header,footer用タブ&URL配列
  if ( setType === "header" || setType === 'footer') {
    allCategoryEdges.map((result) => { // 大カテゴリ名を配列に格納
			subArray.push([result.node.name, 'faq/' + result.node.slug])
      return subArray
    });  
    removals = ['未分類','faq-main-categorie-test']; // 除外大カテゴリ設定
		for(let i = 0; i < subArray.length ; i++){ 
      result = removals.indexOf( subArray[i][0] ); // 外部or内部リンク判別
			if ( result === -1 ) { // 含まない場合
				mainCategUrlArray.push(subArray[i])
			} 
    }
  }
  
  // 配列群作成開始
  if( allEdges !== undefined ){
    for(let i = 0; i < allEdges.length ; i++){ // ユニーク名称
      posts = allEdges[i].node ; // ユニーク名称
      postDate = posts.date ;
      postModified = posts.modified ;
      postId = posts.id ; 
      postUri = posts.uri ; 
      postSlug = posts.slug ; 
      postTitle = posts.title ;
      postExcerpt = posts.excerpt ;
      postDescription = makeContens( postExcerpt )
      if ( setType === "search") {
        postContent = posts.content ;
      }
      postCategories = [];
      postCategsSlug = [];
      if ( posts.faqCategory.nodes[0] !== undefined ) { // ユニーク名称
        for(let j = 0; j < posts.faqCategory.nodes.length ; j++){ // ユニーク名称
          postCategorie = posts.faqCategory.nodes[j].name ; // ユニーク名称
          postCategSlug = posts.faqCategory.nodes[j].slug ;
          postCategories.push(postCategorie); 
          postCategsSlug.push(postCategSlug); 
        }
      }
      postTags = [];
      postTagsSlug = [];
      if ( posts.faqTag.nodes[0] !== undefined ) { // ユニーク名称
        for(let j = 0; j < posts.faqTag.nodes.length ; j++){ // ユニーク名称
          postTag = posts.faqTag.nodes[j].name ; // ユニーク名称
          postTagSlug = posts.faqTag.nodes[j].slug ;
          postTags.push(postTag); 
          postTagsSlug.push(postTagSlug); 
        }
      }
      if ( posts.featuredImage == null || posts.featuredImage.node == null || posts.featuredImage.node.localFile == null) {    
        postGatsbyImageData = '';
        postImageAltText = '';
      } else { 
        if ( posts.featuredImage.node.altText == null ) {    
          postGatsbyImageData = posts.featuredImage.node.localFile.childImageSharp.gatsbyImageData;
          postImageAltText = '';
        } else {
          postGatsbyImageData = posts.featuredImage.node.localFile.childImageSharp.gatsbyImageData;
          postImageAltText = posts.featuredImage.node.altText;
        }
      }
      //各データを配列に 
      arrayPostsData = {Date:postDate, Modified:postModified, Id:postId,Uri:postUri, Slug:postSlug, Title:postTitle, Excerpt:postExcerpt, Description:postDescription, Content:postContent, 
                        Categories:postCategories, CategsSlug:postCategsSlug, Tags:postTags, TagsSlug:postTagsSlug, Image:postGatsbyImageData, AltText:postImageAltText}; 
      
      // １．全記事データ配列
      if ( setType === "setAll" || setType === 'allandMain' || setType === 'allmix' || setType === 'search') {
        alldataArray.push(arrayPostsData); 
      }
      // ２．categorieマッチ 配列作成
      if ( setType === "setCateg" || setType === 'allmix') {
        checkCategorie = checkItem ;
        result = arrayPostsData.Categories.find(item => item === checkCategorie)
        if ( result === checkCategorie ) {
          checkCategArray.push(arrayPostsData);   
        } 
      }
      
      // ３．tagマッチ 配列作成
      if ( setType === "setTag" || setType === 'allmix') {
        checkTag = checkItem ;
        result = arrayPostsData.Tags.find(item => item === checkTag)
        if ( result === checkTag ) {
          checkTagArray.push(arrayPostsData);   
        }
      }
      
      // ４．大カテゴリ別抽出
      if ( setType === "setMainCateg" || setType === 'allandMain' || setType === 'allmix') {
        for ( let j = 0; j < makeCategs.length; j++ ) {
          checkCategorie = mainCategArray[j] ;
          result = arrayPostsData.Categories.find(item => item === checkCategorie)
          if ( result === checkCategorie ) {
            for ( let i = 0; i < arrayPostsData.Categories.length; i++ ) {
              subArray = arrayPostsData.Categories[i];
              if ( subArray !== checkCategorie ) {
                makeCategs[j].push(subArray)
              }
            }
          }
        } 
      } 
    }     
  }

  // カテゴリ検索用
  if ( setType === "setMainCateg" || setType === 'allandMain' || setType === 'allmix') {
    for ( let i = 0; i <  makeCategs.length; i++ ) {
      countLists.push(countList(makeCount(makeCategs[i])))
    }
  }
  
  //========================================================
  // 大カテゴリ別中カテゴリリスト生成（連想配列）
  function makeCount(countArray) {
    let count = [];
    for ( let i = 1; i < countArray.length; i++) {
      let elm = countArray[i];
      count[elm] = (count[elm] || 0) + 1;
    }
    return count
  }
  // 中カテゴリリスト連想配列→通常配列
  function countList(array) {
    let keyList = [];
    let valueList = [];
    let searchList = [];
    let pageCount = 0;
    Object.keys(array).forEach(key => {
      keyList.push(key)
    });
    Object.values(array).forEach(value => {
      valueList.push(value)
    });
    for ( let i = 0; i < keyList.length; i++) {
      searchList.push([keyList[i],valueList[i]])
      pageCount = pageCount + valueList[i] ;
    }
    return [searchList, pageCount]
  }
  // Excerpt→Description変換html調整用関数
  function makeContens( data ){
		let firstItem, midItem, endItem, addNum;
    firstItem = '<p>';
    midItem = '<br />';
    endItem = '</p>';
    addNum = firstItem.length;
    data = data.replace( midItem , '' ) ;
    let index  = data.indexOf(firstItem) + addNum;
    let string = data.substring(index);
    index  = string.indexOf(endItem);
    string = string.substring(0, index);
    return string
  };
  //========================================================

  // 配列作成したらここに追加
  var masterArray = {allData:alldataArray, mainCateg:mainCategArray, checkCateg:checkCategArray, checkTag:checkTagArray, countLists:countLists, mainCategUrl:mainCategUrlArray, searchCateg:searchCategArray};
                    // checkTitle:checkTitleArray　searchCateg:searchCategArray
  return masterArray;
}