import React from "react"
import { graphql } from "gatsby"
import Layout from "@components/layout"
import Seo from "@components/seo"
import "@css/@wordpress/block-library/build-style/style.css"
import "@css/@wordpress/block-library/build-style/theme.css"
import parse from "html-react-parser"

// コンポーネント呼び出し
import { FaqGraphqlArray } from "@components/graphql-data-faq.js"; // 記事情報取得・形成
import { FixedFoot } from "@components/footer";
import { WordSearch } from "@components/word-search.js";

const FaqPost = ({ data: { post }, pageContext }) => {
  const indexUrl = '/';
  const typeUrl = 'faq/';
  const pageTitle = 'よくある質問'
  const name = pageContext.name; // 該当記事名
  const slug = pageContext.slug; // 該当Slug

  const wordSearchContent = WordSearch(indexUrl, typeUrl).Faq; // ワード検索Faq
  const allPostData = FaqGraphqlArray('', 'allandMain');
  const allData = allPostData.allData;
  const mainCategs = allPostData.mainCateg;

  const contentData = post.content;
  
  // 該当記事情報取得(components/graphql-data-post.js)
  let setArray;
  for (let i = 0; i < allData.length ; i++){
    if (allData[i].Title === name){
      setArray = allData[i]; 
    }
  }

  // 大カテゴリ、中カテゴリ選定
  let setMainCateg, result;
  // let setMainCategSlug, setSubCateg
  for (let i = 0; i < setArray.Categories.length ; i++){
    result = mainCategs.find(item => item === setArray.Categories[i])
    if ( result === setArray.Categories[i] ) {
      setMainCateg = setArray.Categories[i]
      // setMainCategSlug = setArray.CategsSlug[i]
    } else {
      // setSubCateg = setArray.Categories[i]
    }
  }
  
  // 記事一覧生成設定
  let selectArray, addCss;
  setArray = allPostData.mainCateg; // 該当カテゴリ配列情報
  
  let faqArray = [];
  setArray.map((result) => {
    selectArray = FaqGraphqlArray( result, 'setCateg').checkCateg;
    if ( selectArray.length !== 0 ) {
      faqArray.push(selectArray)
    }
    return faqArray
  });

  const pickupList = faqArray.map((result) => {
    addCss = setMainCateg === result[0].Categories ? " bg-[#FAA700]" : " bg-[#0054BF]";
    let list = result.map((subResult) => {
      return  <>
      <li className="p-3 border-b-[#0054BF] border-solid border-b-[1px] text-[#0054BF]">
        <a href={indexUrl + typeUrl + subResult.Slug + "/"}>{subResult.Title}</a>
      </li>
      </>
    });
    return  <>
              <div className="md:w-[48%] w-full mb-10 border-[1px] rounded-xl mx-[1%] bg-white" key="" >
                <article className="post-list-item !m-0 h-[100%]"  itemScope itemType="http://schema.org/Article">
                  <div className="w-[100%] h-[100%]">
                   
                    {/* タイトル部分 */}
                    <div className={"w-full text-left h-[20%] px-3 py-2 rounded-tl-xl rounded-tr-xl" + addCss}>
                      <a href={indexUrl + typeUrl + result[0].CategsSlug + "/"}>
                        <p className="CampP md:text-[15px] text-[15px] mb-4 py-2 text-left text-white" >{result[0].Categories[0]}</p>
                      </a>
                    </div>
                    
                    {/* list */}
                    <div className="p-5">
                      {list}
                    </div>
                  </div>
                </article>
              </div>
            </> ;
  });
  
  const fixedItem = FixedFoot( 'nomal' ); // 固定フッター
  
  return (
  <>
    <Layout>
      <Seo
        title={post.title}
        description={setArray.Description}
        ogpUrl={process.env.ORIGINURL + indexUrl + slug + '/'}
        ogpSiteName={post.title}
        ogpImage={post.featuredImage?.node?.guid || ''}
        ogpImageWidth={post.featuredImage?.node?.width || ''}
        ogpImageHeight={post.featuredImage?.node?.height || ''}
        ogpImageType={post.featuredImage?.node?.mimeType || ''}
        modifiedDate={post.modified || ''}
        publishedDate={post.date || ''}
        wpPost = "true"
      />
      <article className="blog-post" itemScope itemType="http://schema.org/Article">
  
        <div className="bg-[#F6F6F6] md:pb-[70px] pb-2">
          <div className="px-5 max-w-xl mx-auto md:max-w-5xl ">
            <div className="pb-4 pt-0 md:pt-10  pt-0  max-w-[800px] ">
              <h2 className="md:text-5xl text-3xl font-black mb-5 text-left pt-5 !leading-[1.2]">{pageTitle}</h2>
              <h3 className="md:text-3xl text-2xl font-black  text-left  text-[#1F55A6]">〇〇〇〇〇〇〇〇〇〇</h3>
            </div> 
          </div>
        </div>

        {/* タイトル */}
        <div className="pt-[70px] max-w-xl mx-auto md:max-w-5xl px-5">
          <div className="text-left mb-10">
            <h2 className=" font-semibold tracking-wide text-[20px] md:text-[26px] text-[#1F55A6] table" itemProp="headline">{ name }</h2>
          </div>   
        </div>  

        {/* 本文 */}
        <ol>
          <div className="md:my-0 md:mx-auto" style={{ paddingTop: 24, paddingBottom: 24, maxWidth: 976}}>
            {!!post.content && (
              <section itemProp="articleBody">{parse(contentData)}</section>
            )}
          </div>
        </ol>

        {/* 記事アーカイブ */}
        <div className="bg-[#F6F6F6] pt-[70px]">
          <div className="max-w-xl mx-auto md:max-w-5xl px-5">
            <div className="flex flex-wrap justify-between items-start">
              {pickupList}
            </div>
          </div>  
        </div>

        {/* 検索窓 */}
        <div className="max-w-xl mx-auto md:max-w-5xl mt-[30px] px-5">
          <div className="h-20">
						{wordSearchContent}
					</div>
        </div>
      
      </article>
      {/* 固定フッター */}
      {fixedItem}
    </Layout>
  </>
  )
}
export default FaqPost

export const pageQuery = graphql`
query FaqPostById(
  $id: String!
) {
  allWpFaq(
    sort: { fields: date, order: DESC }
  ) {
    pageInfo {
      totalCount
    }
    edges {
      node {
        id
        uri
        title
        faqCategory {
          nodes {
            name
          }
        }
        faqTag {
          nodes {
            name
          }
        }
      }
    }
  }
  post: wpFaq(id: { eq: $id }) {
    id
    excerpt
    content
    title
    uri
    modified
    faqCategory {
      nodes {
        name
      }
    }
    date(formatString: "MMMM DD, YYYY")
    featuredImage {
      node {
        altText
        guid
        mimeType
        width
        height
        localFile {
          childImageSharp {
            gatsbyImageData(
              quality: 100
              placeholder: TRACED_SVG
              layout: FULL_WIDTH
            )
          }
        }
      }
    }
  }
}
`